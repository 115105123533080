import React from "react"

import MainContainer from "../components/containers/MainContainer"
import HeadData from "../data/HeadData"
import OneColumnPageLayout from "../components/layouts/OneColumnPageLayout";
import ContactForm from "../components/forms/ContactForm"

const Contact = () => (
  <MainContainer>
    <HeadData
      title='Contact Page'
      description='Just another contact page'
    />

    <OneColumnPageLayout>
      <h2 className='font-display text-4xl text-center leading-tight mb-4'>Get In Touch</h2>
    </OneColumnPageLayout>

    <ContactForm />
  </MainContainer>
)

export default Contact
