import React, { Component } from 'react'
import axios from 'axios'
import SimpleReactValidator from 'simple-react-validator'

const API_PATH = 'https://api.ocsigs.com/contact/index.php';

class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.validator = new SimpleReactValidator()
    this.state = {
      fname: '',
      lname: '',
      email: '',
      phone: '',
      member: '',
      school: '',
      graduated: '',
      message: '',
      showFields: null,
      mailSent: false,
      mailError: null,
      loadingState: null,
    }
  }

  handleFormSubmit = e => {
    e.preventDefault()

    this.setState({
      loadingState: true
    })

    if (this.validator.allValid()) {
      axios({
        method: 'post',
        url: `${API_PATH}`,
        headers: { 'content-type': 'application/json' },
        data: this.state
      })
      .then(result => {
        this.setState({
          loadingState: false,
          mailSent: result.data.sent
        })
      })
      .catch(error => {
        this.setState({
          loadingState: false,
          mailError: error.message
        })
      })
    } else {
      this.setState({
        loadingState: false,
      })
      this.validator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
    }
  }

  render() {
    return (
      <form className='container w-full mx-auto mb-16 max-w-lg' action='#'>
        <div className="flex flex-wrap mx-3">
          <div className="w-full lg:w-1/2 xl:w-1/2 px-3 mb-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="fname">
              First Name
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="fname"
              type="text"
              placeholder="First Name"
              value={this.state.fname}
              onChange={e => this.setState({ fname: e.target.value })}
              required
            />
            {this.validator.message('first_name', this.state.fname, 'required|alpha_space', {className: 'text-red-500 text-xs italic'})}
          </div>

          <div className="w-full lg:w-1/2 xl:w-1/2 px-3 mb-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="lname"
            >
              Last Name
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="lname"
              type="text"
              placeholder="Last Name"
              value={this.state.lname}
              onChange={e => this.setState({ lname: e.target.value })}
              required
            />
            {this.validator.message('last_name', this.state.lname, 'required|alpha_space', {className: 'text-red-500 text-xs italic'})}
          </div>
        </div>

        <div className="mx-3 mb-6">
          <div className="w-full px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="email"
              type="email"
              placeholder='name@example.com'
              value={this.state.email}
              onChange={e => this.setState({ email: e.target.value })}
              required
            />
            {this.validator.message('email', this.state.email, 'required|email', {className: 'text-red-500 text-xs italic'})}
          </div>
        </div>

        <div className="mx-3 mb-6">
          <div className="w-full px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="phone"
            >
              Phone
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="phone"
              type="number"
              placeholder='1234567890'
              value={this.state.phone}
              onChange={e => this.setState({ phone: e.target.value })}
              required
            />
            {this.validator.message('phone', this.state.phone, 'required|numeric', {className: 'text-red-500 text-xs italic'})}
          </div>
        </div>

        <div className="mx-3 mb-6">
          <div className="w-full px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="member"
            >
              Are you a Sigma Chi?
            </label>
            <input
              className="text-gray-600"
              id="yes"
              name='member'
              type="radio"
              value="yes"
              onChange={e => this.setState({ member: e.target.value, showFields: true })}
            />
            <label htmlFor="yes" className='ml-2 mr-4'>Yes</label>
            <input
              className="text-gray-600"
              id="no"
              name='member'
              type="radio"
              value="No"
              onChange={e => this.setState({ member: e.target.value, showFields: false })}
            />
            <label htmlFor="no" className='ml-2 mr-4'>No</label>
            {this.validator.message('membership', this.state.member, 'required', {className: 'text-red-500 text-xs italic'})}
          </div>
        </div>

        {this.state.showFields &&
          <div className="flex flex-wrap mx-3">
            <div className="w-full lg:w-1/2 xl:w-1/2 px-3 mb-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="school">
                Alma Mater
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="school"
                type="text"
                placeholder="Alma Mater"
                value={this.state.school}
                onChange={e => this.setState({ school: e.target.value })}
              />
              {this.validator.message('alma_mater', this.state.school, 'alpha_space', {className: 'text-red-500 text-xs italic'})}
            </div>

            <div className="w-full lg:w-1/2 xl:w-1/2 px-3 mb-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="graduated"
              >
                Graduation Year
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="graduated"
                type="number"
                placeholder='2001'
                value={this.state.graduated}
                onChange={e => this.setState({ graduated: e.target.value })}
              />
              {this.validator.message('graduation_year', this.state.graduated, 'numeric', {className: 'text-red-500 text-xs italic'})}
            </div>
          </div>
        }

        <div className="mx-3 mb-8">
          <div className="w-full px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="message"
            >
              Message
            </label>
            <textarea
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="message"
              placeholder='Your message here.'
              value={this.state.message}
              onChange={e => this.setState({ message: e.target.value })}
              required
            />
            {this.validator.message('message', this.state.message, 'required|max:1000', {className: 'text-red-500 text-xs italic'})}
          </div>
        </div>

        <div className="mx-3 mb-6">
          {this.state.mailSent &&
            <div className='mx-3 mb-6' role='alert'>
              <div className="bg-green-500 text-white font-bold px-4 py-2">
                Thank you!
              </div>
              <div className="border border-t-0 border-green-400 bg-green-100 px-4 py-3 text-green-700">
                <p>Your message has been sent. We will contact you soon.</p>
              </div>
            </div>
          }

          {this.state.mailError &&
            <div className='mx-3 mb-6' role='alert'>
              <div className="bg-red-500 text-white font-bold px-4 py-2">
                Oops!
              </div>
              <div className="border border-t-0 border-red-400 bg-red-100 px-4 py-3 text-red-700">
                <p>Sorry, there was a problem sending your message. Please refresh browser and try again. If problem persists, please use other contact channels. Thanks!</p>
              </div>
            </div>
          }

          <button
            className="bg-blue-800 hover:bg-blue-900 text-white text-lg font-bold py-3 px-6 mt-6 rounded"
            type='submit'
            onClick={e => this.handleFormSubmit(e)}
          >
            {this.state.loadingState &&
              <svg className="animate-spin h-5 w-5 mr-3 text-white inline-block" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            }
            Send Message
          </button>
        </div>

      </form>
    )
  }
}

export default ContactForm